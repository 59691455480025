<template>
  <div id="schedule-imports-details-table">
    <Deliveries
      ref="modalDeliveries"
      :isOpen.sync="showModal"
      :delivery="data"
      @save="$refs.Table.getData()"
      @patchReady="readyPatch"
      :deliveryImport="deliveryImport"
      scope="IMPORT"
    />
    <Table
      :striped="false"
      :focusable="true"
      :loading.sync="oLoading.table"
      paginated
      detailed
      :show-detail-icon="false"
      :apiUrl="'file_import/' + group_id + '/deliveries_import'"
      :params="aParams"
      class="imports-table"
      ref="table"
      isRowClass
    >
      <b-table-column :label="$t('schedule.deliveryDate')" v-slot="props" :td-attrs="tdAttrs">
        {{ formatDate(props.row.delivery_date) || '-' }}
      </b-table-column>
      <b-table-column :label="$t('global.orderNumber')" v-slot="props" :td-attrs="tdAttrs">
        <div
          class="order-number-duplicated"
          v-if="
            parseDataElement(props, 'error') === 'ORDER_NUMBER_DUPLICATED' &&
            props.row.ready_to_assign !== 1
          "
        >
          <p>{{ props.row.tracking_number || '-' }}</p>
          <Link
            :autoLoading="false"
            :label="$t('global.orderNumberDuplicated')"
            class="ml-2"
            icon="information-outline"
          />
        </div>
        <template v-else>
          {{ props.row.tracking_number || '-' }}
        </template>
      </b-table-column>
      <b-table-column :label="$t('participant.clientCode')" v-slot="props" :td-attrs="tdAttrs">
        {{ props.row.client_code || '-' }}
      </b-table-column>
      <b-table-column
        :label="$t('participant.name')"
        v-slot="props"
        :td-attrs="tdAttrs"
        width="200"
      >
        <p v-if="props.row.participant_is_active === 0" class="danger flex f-ai">
          <Tooltip :label="$t('schedule.participantDeactivated')" class="mr-2" :position="position">
            <b-icon icon="alert-circle-outline" />
          </Tooltip>
          {{ props.row.participant_full_name || props.row.full_name || '-' }}
        </p>
        <p v-else>
          {{ props.row.participant_full_name || props.row.full_name || '-' }}
        </p>
      </b-table-column>
      <b-table-column :label="$t('participant.address')" v-slot="props" :td-attrs="tdAttrs">
        {{ props.row.participant_full_address || props.row.full_address || '-' }}
      </b-table-column>
      <b-table-column :label="$t('global.copayment')" v-slot="props" :td-attrs="tdAttrs">
        <CopaymentTag :value="props.row.copayment_amount" />
      </b-table-column>
      <b-table-column
        :label="$tc('global.comment', 2)"
        v-slot="props"
        :td-attrs="tdAttrs"
        cell-class="is-hidden-xl"
        header-class="is-hidden-xl"
      >
        {{ props.row.comments || '-' }}
      </b-table-column>
      <b-table-column v-slot="props" :td-attrs="tdAttrs" width="110">
        <div class="flex">
          <Link
            :autoLoading="false"
            @click="onEdit(parseDataElement(props, 'new_unattended'), props.row)"
            icon="pencil"
            :label="$t('button.edit')"
            v-if="hasPermissionOnParticipant && isValidZipcode(parseDataElement(props, 'error'))"
          />

          <Link
            :autoLoading="false"
            icon="account-sync"
            class="danger"
            :label="$t('button.reactivate')"
            @click="onParticipant(props.row)"
            v-if="props.row.participant_is_active === 0"
          />

          <Link
            :autoLoading="false"
            icon="account-search"
            :label="$t('button.review.participant')"
            @click="onParticipant(props.row)"
            v-if="
              (parseDataElement(props, 'new_participant') ? true : false) &&
              hasPermissionOnParticipant &&
              props.row.participant_is_active
            "
          />
          <Link
            :autoLoading="false"
            icon="home-search"
            :label="$t('button.review.address')"
            @click="onAddress(props.row)"
            v-if="
              (parseDataElement(props, 'new_address') ? true : false) &&
              hasPermissionOnParticipant &&
              props.row.participant_is_active
            "
          />
          <Link
            :autoLoading="false"
            icon="hospital-building"
            :label="$t('button.review.cdp')"
            @click="onCDP(props.row)"
            v-if="
              (parseDataElement(props, 'new_cdp') ? true : false) &&
              hasPermissionOnCDP &&
              props.row.participant_is_active
            "
          />
          <!-- <Link
            class="action-buttons"
            :autoLoading="false"
            icon="alert-octagon-outline"
            :label="$t('schedule.sendEmailReport')"
            @click="onSendEmail(props.row)"
          /> -->
          <Link
            @click="toggleDetails(props.row)"
            :autoLoading="false"
            class="action-buttons ml-auto"
            :icon="props.row.isOpen ? 'chevron-up' : 'chevron-down'"
            :label="props.row.isOpen ? $t('global.lessDetails') : $t('global.moreDetails')"
          />
        </div>
      </b-table-column>
      <template #details="props">
        <div class="flex">
          <div>
            <p class="w-100 has-text-weight-bold">Lat/Long</p>
            <p>
              {{
                props.row['participant_address.latitude'] &&
                props.row['participant_address.longitude']
                  ? $t('global.yes')
                  : 'No'
              }}
            </p>
          </div>
          <div>
            <p class="w-200 has-text-weight-bold">{{ $t('contact.phoneNumber') }}</p>
            <p>{{ props.row.phone_number || props.row.mobile_phone_number || '-' }}</p>
          </div>
          <div class="is-visible-xl">
            <p class="w-250 has-text-weight-bold">{{ $t('global.status') }}</p>
            <p>{{ props.row.comments || '-' }}</p>
          </div>
        </div>
        <div class="mt-4" v-if="props.row.comments">
          <p class="w-250 has-text-weight-bold">{{ $tc('global.comment', 2) }}</p>
          <p>{{ props.row.comments || '-' }}</p>
        </div>
        <div class="flex">
          <div
            class="mt-4 mr-4"
            v-if="parseDataElement(props, 'error') && props.row.ready_to_assign !== 1"
          >
            <p class="w-250 has-text-weight-bold">{{ $tc('global.error', 2) }}</p>
            {{ parseDataElement(props, 'error') }}
          </div>
          <div class="mt-4" v-if="parseDataElement(props, 'warning')">
            <p class="w-250 has-text-weight-bold">{{ $tc('global.warning', 2) }}</p>
            {{ parseDataElement(props, 'warning') }}
          </div>
        </div>
      </template>
    </Table>
  </div>
</template>

<script>
import { Link, Table, Tooltip } from '@/components';
import { Permissions } from '@/utils/Secure';
import Deliveries from '@/views/Fragments/DeliveriesModal';
import { pick } from 'ramda';
import CopaymentTag from '../../CopaymentTag.vue';

export default {
  components: {
    Link,
    Table,
    Deliveries,
    Tooltip,
    CopaymentTag
  },
  created() {
    this.unsubscribe = this.$store.subscribe(({ type }, { schedule: { currentManifest } }) => {
      if (type == 'schedule/currentManifest') {
        this.manifest = currentManifest;
      }
    });
  },
  destroyed() {
    this.unsubscribe();
  },
  data() {
    return {
      oLoading: { table: this.loading, rowId: 0 },
      manifest: null,
      unsubscribe: null,
      showModal: null,
      data: null,
      deliveryImport: null,
      unattendedId: null,
      groupId: null,
      deliveriesImportId: null
    };
  },
  computed: {
    hasPermissionOnParticipant() {
      let isAllowed = false;
      if (Permissions.Participants.update?.length) {
        isAllowed = Permissions.Participants.update.some((permission) =>
          this.Secure.permissionValidator(permission)
        );
      }
      return isAllowed || this.Secure.permissionValidator(Permissions.Participants.update);
    },
    hasPermissionOnCDP() {
      let isAllowed = false;
      if (Permissions.CDPs.update?.length) {
        isAllowed = Permissions.CDPs.update.some((permission) =>
          this.Secure.permissionValidator(permission)
        );
      }
      return isAllowed || this.Secure.permissionValidator(Permissions.CDPs.update);
    },
    group_id() {
      return this.$route.path.split('/')[3];
    },
    bTable() {
      return this.$refs?.table?.bTable;
    },
    aParams() {
      let aParams = ['sort=id'];
      Object.keys(this.params).map((key) => {
        let value = this.params[key];
        if (value) aParams.push(`${key}=${value}`);
      });
      return aParams;
    }
  },
  methods: {
    classRow(stringValue) {
      if (stringValue?.ready_to_assign !== 1) {
        const data = JSON.parse(stringValue?.result_import);
        return data?.result?.toLowerCase() || '';
      }
      return false;
    },
    formatDate(date) {
      const currentDate = this.$moment(date, 'MM/DD/YYYY').format('MM/DD/YYYY');
      return this.$moment(currentDate, 'MM/DD/YYYY').isValid() ? currentDate : null;
    },
    isValidDate(date) {
      const currentDate = this.formatDate(date);
      if (this.$moment(currentDate, 'MM/DD/YYYY').diff(this.$moment().format('MM/DD/YYYY')) >= 0)
        return true;
      else return false;
    },
    async onEdit(EditId, data) {
      const { group_id, id } = data;
      this.groupId = group_id;
      this.deliveriesImportId = id;
      this.deliveryImport = id.toString();
      this.showModal = true;

      if (EditId) {
        try {
          this.$refs.modalDeliveries.loading.get = true;
          const { data } = await this.Api.get(`/delivery_manifest/unattended_deliveries/${EditId}`);
          data.deliveries_import_id = id.toString();
          this.data = data;
        } catch (error) {
          console.log(error);
        }
      } else {
        const pickDeliverySequence = (data) =>
          pick([
            'comments',
            'copayment_amount',
            'is_collect_event',
            'leave_at_door',
            'participant_is_active',
            'priority',
            'refrigeration_needed',
            'rush',
            'tracking_number'
          ])(data);

        this.data = {
          participant_address: data.full_address,
          participant_full_name: data.full_name,
          delivery_date: this.$moment(data.delivery_date).utc().format('YYYY-MM-DD 00:00:00'),
          participant_mobile_phone_number: data.mobile_phone_number,
          ...pickDeliverySequence(data)
        };
      }
      this.unattendedId = EditId;
      this.$refs.modalDeliveries.loading.get = false;
    },
    async handleUpdate() {
      await this.$refs.table.getTableData(true);
      this.oLoading.table = false;
    },
    async readyPatch(data) {
      this.oLoading.table = true;
      const {
        id,
        participant_id,
        participant_is_active,
        participant_address_id,
        phone_number_id,
        mobile_phone_number_id
      } = data;

      if (this.unattendedId) {
        try {
          await this.Api.patch(`/delivery_manifest/unattended_deliveries/${data.id}/ready`, data);
        } catch (error) {
          console.log(error);
        }
      }

      if (!this.data?.participant_id && !this.data?.participant_address_id) {
        await this.Api.put(
          `/file_import/${this.groupId}/deliveries_import/${this.deliveriesImportId}`,
          {
            new_unattended: id,
            new_participant: participant_id,
            new_address: participant_address_id,
            new_participant_is_active: participant_is_active,
            new_phone: mobile_phone_number_id || phone_number_id
          }
        );
      }

      this.handleUpdate();
    },
    isValidZipcode(error) {
      if (error === 'Invalid zipcode, only allowed from CA') return false;
      return true;
    },
    parseDataElement(props, element) {
      let userObj = JSON.parse(props.row?.result_import || '[]');
      return userObj[element];
    },
    async getData() {
      await this.$refs.table.getData({ clear: true });
    },
    onClick(value) {
      this[value.event]();
    },
    onSendEmail(row) {
      console.log('Send email...');
      console.log(row);
    },
    onParticipant(row) {
      this.$router.push({ path: `/data/participants/${row.participant_id}` });
    },
    onAddress(row) {
      this.$router.push({ path: `/data/participants/${row.participant_id}/location` });
    },
    onCDP(row) {
      const cdpId = this.parseDataElement({ row }, 'new_cdp');
      this.$router.push({ path: `/data/cdps/${cdpId}` });
    },
    columnClassByRow(row = {}) {
      let classes = [];
      if (!row.is_active) classes.push('inactive');
      if (row.isOpen) classes.push('isOpen');
      return classes.join(' ');
    },
    tdAttrs(row) {
      return { class: this.columnClassByRow(row) };
    },
    toggleDetails(row) {
      row.isOpen = !row.isOpen;
      this.bTable.toggleDetails(row);
    }
  },
  watch: {
    loading(value) {
      this.oLoading.table = value;
    },
    'oLoading.table'(value) {
      this.$emit('update:loading', value);
    }
  },
  props: {
    loading: { type: Boolean, default: false },
    position: { type: String, default: 'bottom' },
    params: {
      type: Object,
      default: () => {}
    }
  }
};
</script>
<style lang="sass" scoped>
.danger
    color: $red-700
#schedule-imports-details-table
  border-radius: $br-md
  .table-container
    min-height: calc(100% - 120px)
.imports-table
  ::v-deep
    .is-selected
      cursor: default
      .action-buttons
          visibility: visible
    th
      z-index: 39!important
    tr
      .isOpen:first-child,
      .isOpen:last-child
        border-bottom-left-radius: 0
        border-bottom-right-radius: 0
      &:hover
        cursor: pointer
        .action-buttons
          visibility: visible
    &.isOpen
      .action-buttons
        visibility: visible
    .table-wrapper
      height: calc(100vh - 285px)
    .is-hidden-xl
      display: table-cell
  .action-buttons
    visibility: hidden
  .order-number-duplicated
    color: $red-400
    font-weight: 600
    display: flex
    ::v-deep .link
      color: $red-400 !important
.w-100
  width: 100px
.w-200
  width: 200px
.w-250
  width: 250px
</style>
