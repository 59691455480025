<template>
  <Card>
    <template #header>
      <StepCardHeader>
        <Input-search
          class="search-field"
          icon="magnify"
          v-model="params.search_by"
          :placeholder="$t('schedule.search.importsDetails')"
          :disabled="disabled"
          :timeout="1000"
        />
      </StepCardHeader>
    </template>
    <div>
      <div id="imports-details-table">
        <MainTable ref="Table" :loading.sync="loading" :params="params" />
      </div>

      <div class="flex f-jce">
        <Button
          type="is-primary"
          :label="$t('schedule.proceedScheduling')"
          @click="() => $router.push({ path: '/schedule/scheduling' })"
          :permission="Permission.readAll"
        />
      </div>
    </div>
  </Card>
</template>

<script>
import { Button, Card, InputSearch, StepCardHeader } from '@/components';
import MainTable from './MainTable.vue';
import { Permissions } from '@/utils/Secure';

export default {
  components: {
    Button,
    Card,
    InputSearch,
    MainTable,
    StepCardHeader
  },
  data() {
    return {
      disabled: false,
      loading: false,
      params: { search_by: null }
    };
  },
  computed: {
    Permission() {
      return Permissions.Scheduling;
    }
  }
};
</script>

<style lang="sass" scoped>
.card
  height: calc(100% - 100px)
  .stepCardHeader
    min-height: fit-content!important
  .search-field
    width: 100%
    padding-left: 20px
  ::v-deep
    .card-content,
    .card-content > .flex
      height: 100%
  #imports-details-table
    ::v-deep
      .table-wrapper
        margin-bottom: 0
      .level-right
        margin-bottom: 1.5rem
</style>
